import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get, set } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_URL, STATUS_MESSAGES } from 'src/app/GlobalConstants';
import { constructUserInformation } from 'src/app/client/shared/user-information.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { JobFlowLog } from '../../shared/job-summary/job-summary.model';
import { JobFlowInformation, LogItem, LogItemLists } from './log-details.model';

@Injectable({
  providedIn: 'root'
})
export class LogDetailsService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);

  private logDetailsUrl = API_SERVICE_URL + '/worklog';
  private logItemLists: LogItemLists = {};
  private jobFlowInformation: JobFlowInformation = {};

  getLogDetails(requisitionId: number): Observable<LogItem[]> {
    if (!requisitionId) {
      return this.pageService.errorHelper('getLogDetails: missing the required params.');
    }
    const existingValue = get(this.logItemLists, requisitionId);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'requestDetail');

    if (!reloadStatus && existingValue) {
      return of(existingValue);
    }
    const logDetailsUrl = `${this.logDetailsUrl}?reqId=${requisitionId}`;
    return this.http.get<LogItem[]>(logDetailsUrl)
      .pipe(
        map((logItems: LogItem[]) => {
          const modifiedLogItems = logItems.map((logItem: LogItem) => {
            return {
              ...logItem,
              statusDescription: STATUS_MESSAGES[logItem.requestStatus as number].description,
              userInformation: constructUserInformation(logItem, { showDescription: true }),
            };
          });
          set(this.logItemLists, requisitionId, modifiedLogItems);
          return modifiedLogItems;
        }),
      );
  }

  setJobFlow(serialNumber: string, jobFlow: JobFlowLog[]): void {
    this.jobFlowInformation[serialNumber] = jobFlow;
  }

  getJobFlow(serialNumber: string): JobFlowLog[] {
    return get(this.jobFlowInformation, serialNumber, []);
  }

  resetCache() {
    this.logItemLists = {};
    this.jobFlowInformation = {};
  }
}
