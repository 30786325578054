import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { tap } from 'rxjs';
import { ADMIN_ROLE, API_SERVICE_URL, MANAGER_ROLE, SPECIAL_ROLE } from '../GlobalConstants';
import { CollectionService } from '../shared/utilities/collection.service';
import { getAuthValue, setAuthValue } from '../shared/utilities/helper';
import { Login, LoginData, LoginRequest, Profile } from './login.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private http = inject(HttpClient);
  private collectionService = inject(CollectionService);

  public profileData = signal<Profile | null>(null);
  public loginData = signal<LoginData | null>(null);
  private _authFlag = 'loginToken';

  public assetUrl = `${API_SERVICE_URL}/materialrequisition/file`;

  constructor() {
    const authValue: Login = getAuthValue(this._authFlag);
    if (Object.keys(authValue).length !== 0) {
      this.setLoginData(authValue);
    }
  }

  verifyLogin(loginParam: LoginRequest) {
    const loginUrl = API_SERVICE_URL + '/login';
    return this.http.post<Login>(loginUrl, loginParam)
      .pipe(
        tap((loginInfo: Login) => {
          this.setAuth(loginInfo);
          this.setLoginData(loginInfo);
        })
      );
  }

  resetAuthFlag() {
    this.collectionService.resetCache();
    localStorage.removeItem(this._authFlag);
    this.loginData.set(null);
    this.profileData.set(null);
  }

  private setLoginData(loginInfo: Login) {
    this.loginData.set({
      token: loginInfo.token,
      role: loginInfo.role,
      isAdmin: ADMIN_ROLE === loginInfo.role,
      isSpecialRole: SPECIAL_ROLE === loginInfo.role,
      isManagerRole: MANAGER_ROLE === loginInfo.role,
      isAuthenticated: loginInfo.token !== '',
      refreshToken: loginInfo.refreshToken,
    });
    if (loginInfo?.profile) {
      this.profileData.set(loginInfo.profile);
    }
  }

  setAuth(loginInfo: Login) {
    setAuthValue(this._authFlag, loginInfo);
  }

  logout() {
    const refreshToken = this.loginData()?.refreshToken;
    const deleteTokenUrl = API_SERVICE_URL + `/login/terminate/${refreshToken}`;
    this.resetAuthFlag();
    return this.http.delete<Login>(deleteTokenUrl);
  }
}
