import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { get, isEmpty, set } from 'lodash';
import { Observable, map, of } from "rxjs";
import { ADMIN_OVERRIDE_CODE, API_SERVICE_URL, REJECTED_CODE, REVISION_CODE } from "src/app/GlobalConstants";
import { ApiReloadStatus } from "src/app/shared/includes/page-header/page-header.model";
import { PageHeaderService } from "src/app/shared/includes/page-header/page-header.service";
import { getRequestStatus } from "src/app/shared/utilities/helper";
import { TaskItem } from "../../my-task/my-task.model";
import { JobReportPageDataModel, JobReportRequest, JobReportResponse, JobReportResponseTime } from "./job-request.model";

@Injectable({
  providedIn: 'root',
})
export class JobRequestService {
  private http = inject(HttpClient);
  private datePipe = inject(DatePipe);
  private pageService = inject(PageHeaderService);

  private jobRequestData: JobReportResponse = {};
  private assetReportPageData: JobReportPageDataModel | null = null;
  getJobReport(param: JobReportRequest): Observable<JobReportResponseTime> {
    const {
      jobId,
      companyId,
    } = param;
    const jobReportUrl = `${API_SERVICE_URL}/materialrequisition/job?companyId=${companyId}&jobId=${jobId}`;
    const reloadStatus: boolean = get(this.pageService.apiReloadStatus$.value, 'jobReportSummary', false);
    const existingValue = get(this.jobRequestData, `[${jobId}]`);
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    return this.http.get<TaskItem[]>(jobReportUrl)
      .pipe(
        map((taskItems: TaskItem[]) => {
          const formattedData = taskItems.map((requestItem: TaskItem) => ({
            ...requestItem,
            serialNumber: requestItem.serialNumber,
            jobCode: requestItem.jobCode,
            jobName: requestItem.jobName,
            companyName: requestItem.companyName,
            requestedOn: this.datePipe.transform(`${requestItem.dateCreated}Z`, 'MMM dd, yyyy hh:mm a'),
            statusDescription: getRequestStatus(requestItem.requestStatus, requestItem.currentStatus),
            isRequestOnError: [ADMIN_OVERRIDE_CODE, REVISION_CODE, REJECTED_CODE].includes(Number(requestItem.requestStatus)),
          })) as TaskItem[];

          const revisedResponse: JobReportResponseTime = {
            requestAsOf: new Date(),
            jobItems: formattedData,
          };

          set(this.jobRequestData, `[${jobId}]`, revisedResponse);
          this.updateReloadStatus({ jobReportSummary: false });
          return revisedResponse;
        })
      );
  }

  public setPageData(data: JobReportPageDataModel) {
    this.assetReportPageData = data;
  }

  public getPageData(): JobReportPageDataModel {
    return this.assetReportPageData as JobReportPageDataModel;
  }

  public updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param)

  resetCache() {
    this.jobRequestData = {};
    this.assetReportPageData = null;
  }
}
