import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { get, set } from 'lodash';
import { Observable, map, of } from 'rxjs';
import {
  ADMIN_OVERRIDE_CODE, API_SERVICE_URL, REJECTED_CODE, REVISION_CODE, STATUS_MESSAGES
} from 'src/app/GlobalConstants';
import { ApiReloadStatus } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { RequestDetail } from '../request-details/request-detail.model';
import { AttachmentViewer } from '../shared/attachments/attachments.model';
import { AttachmentsService } from '../shared/attachments/attachments.service';
import {
  MyRequestPageDataModel, MyRequestSummaryStatus, MyRequestSummaryTime, RequestSummaryRequest
} from './my-request.model';

@Injectable({
  providedIn: 'root'
})
export class MyRequestService {
  private http = inject(HttpClient);
  private datePipe = inject(DatePipe);
  private attachmentService = inject(AttachmentsService);
  private pageService = inject(PageHeaderService);

  private myRequestData = signal<MyRequestSummaryStatus>({});
  private myRequestPageData: MyRequestPageDataModel = {} as MyRequestPageDataModel;

  getMaterialRequisition(param: RequestSummaryRequest): Observable<MyRequestSummaryTime> {
    const {
      requestStatus = 'progress',
      numberOfDays = 0,
    } = param;

    const existingValue = get(this.myRequestData(), `[${requestStatus}][${numberOfDays}]`);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'myRequestSummary');
    if (!reloadStatus && existingValue) {
      return of(existingValue);
    }

    const requestSummaryUrl = `${API_SERVICE_URL}/materialrequisition/myrequest?status=${requestStatus}&days=${numberOfDays}`;
    return this.http.get<RequestDetail[]>(requestSummaryUrl)
      .pipe(
        map(requestSummary => {
          const processedRequestSummary: RequestDetail[] = requestSummary
            .map((requestItem: RequestDetail) => ({
              ...requestItem,
              serialNumber: requestItem.serialNumber,
              jobCode: requestItem.jobCode,
              jobName: requestItem.jobName,
              companyName: requestItem.companyName,
              dateCreated: this.datePipe.transform(`${requestItem.dateCreated}Z`, 'MMM dd, yyyy hh:mm a') || '',
              requestStatus: STATUS_MESSAGES[Number(requestItem.requestStatus)].status,
              isRequestOnError: [ADMIN_OVERRIDE_CODE, REVISION_CODE, REJECTED_CODE].includes(Number(requestItem.requestStatus)),
            }));
          const dataToStore: MyRequestSummaryTime = {
            requestAsOf: new Date(),
            requests: processedRequestSummary
          }
          this.myRequestData.update(prevValue => set(prevValue, `[${requestStatus}][${numberOfDays}]`, dataToStore));
          this.setPageData({
            ...this.myRequestPageData,
            requestStatus: param.requestStatus,
            numberOfDays: param.numberOfDays,
            isTeamRequests: false,
            pageIndex: 0,
          } as MyRequestPageDataModel);
          this.updateReloadStatus({ myRequestSummary: false });
          return dataToStore;
        })
      );
  }

  public getAttachment(requestId: number): Observable<AttachmentViewer[]> {
    return this.attachmentService.getAttachments(requestId);
  }

  public setPageData(data: MyRequestPageDataModel) {
    this.myRequestPageData = data;
  }

  public getPageData(): MyRequestPageDataModel | null {
    return this.myRequestPageData;
  }

  public updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param)

  resetCache() {
    this.myRequestData.set({});
    this.myRequestPageData = {} as MyRequestPageDataModel;
  }
}
