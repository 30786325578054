import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get, isEmpty, isNil, set } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ADMIN_OVERRIDE_CODE, API_SERVICE_URL, COMPLETED_CODE, REJECTED_CODE, REVISION_CODE } from 'src/app/GlobalConstants';
import { ApiReloadStatus } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { getRequestStatus } from 'src/app/shared/utilities/helper';
import { JobMaterial, JobMaterialReportPageDataModel, JobMaterialResponse, JobMaterialResponseTime } from './job-material.model';

const MATERIAL_REQUEST_URL = `${API_SERVICE_URL}/materialrequisition`;

@Injectable({
  providedIn: 'root',
})
export class JobMaterialService {
  private http = inject(HttpClient);
  private datePipe = inject(DatePipe);
  private pageService = inject(PageHeaderService);

  private jobMaterialData: JobMaterialResponse = {};
  private jobMaterialReportPageData: JobMaterialReportPageDataModel = {} as JobMaterialReportPageDataModel;

  getJobMaterialReport(jobId: number): Observable<JobMaterialResponseTime> {
    if (!jobId) {
      return this.pageService.errorHelper('getJobMaterialReport: missing the required params.');
    }

    const existingValue = get(this.jobMaterialData, `[${jobId}]`);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'jobReportDetail');

    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    const jobMaterialReportUrl = `${MATERIAL_REQUEST_URL}/material?jobId=${jobId}`;
    return this.http.get<JobMaterial[]>(jobMaterialReportUrl)
      .pipe(
        map(materialResponse => {
          const formattedResponse = materialResponse.reduce((acc: JobMaterial[], materialItem: JobMaterial) => {
            if (!isNil(materialItem.requestStatus) && materialItem.requestStatus <= COMPLETED_CODE) {
              acc.push({
                ...materialItem,
                quantityWithUnit: `${materialItem.quantity} ${materialItem.unit}`,
                asset: materialItem.isAsset ? 'Asset' : 'No',
                statusDescription: getRequestStatus(materialItem.requestStatus, materialItem.currentStatus),
                storeDate: this.datePipe.transform(`${materialItem.storeDate}Z`, 'MMM dd, yyyy hh:mm a') || '',
                isRequestOnError: [ADMIN_OVERRIDE_CODE, REVISION_CODE, REJECTED_CODE].includes(Number(materialItem.requestStatus)),
              });
            }
            return acc;
          }, []);

          const revisedResponse: JobMaterialResponseTime = {
            requestAsOf: new Date(),
            jobItemDetails: formattedResponse,
          };

          this.updateReloadStatus({ jobReportDetail: false });

          set(this.jobMaterialData, `[${jobId}]`, revisedResponse);
          return revisedResponse;
        }),
      );
  }

  public setPageData(data: JobMaterialReportPageDataModel) {
    this.jobMaterialReportPageData = data;
  }

  public getPageData(): JobMaterialReportPageDataModel {
    return this.jobMaterialReportPageData;
  }

  public updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param)

  resetCache() {
    this.jobMaterialData = {};
    this.jobMaterialReportPageData = {} as JobMaterialReportPageDataModel;
  }
}
