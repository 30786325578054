import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { get, isEmpty } from 'lodash';
import { Observable, map, of } from "rxjs";
import { ADMIN_OVERRIDE_CODE, API_SERVICE_URL, REJECTED_CODE, REVISION_CODE, STATUS_MESSAGES } from "src/app/GlobalConstants";
import { ApiReloadStatus } from "src/app/shared/includes/page-header/page-header.model";
import { PageHeaderService } from "src/app/shared/includes/page-header/page-header.service";
import { AssetReportModel, AssetReportPageDataModel, AssetReportTime } from "./asset-report.model";

@Injectable({
  providedIn: 'root',
})
export class AssetReportService {
  private http = inject(HttpClient);
  private datePipe = inject(DatePipe);
  private pageService = inject(PageHeaderService);

  private assetData: AssetReportTime | null = null;
  private assetReportPageData: AssetReportPageDataModel | null = null;
  getAssetReport(): Observable<AssetReportTime> {
    let assetReportUrl = `${API_SERVICE_URL}/materialrequisition/assets`;
    const reloadStatus: boolean = get(this.pageService.apiReloadStatus$.value, 'assetReport', false);
    const existingValue: AssetReportTime = this.assetData as AssetReportTime;
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    return this.http.get<AssetReportModel[]>(assetReportUrl)
      .pipe(
        map(assetResponse => {
          const structuredResponse = assetResponse.map((assetItem: AssetReportModel) => ({
            ...assetItem,
            partName: assetItem.additionalInfo,
            assetStatus: STATUS_MESSAGES[assetItem.flowStatus].status,
            isRequestOnError: [REVISION_CODE, REJECTED_CODE, ADMIN_OVERRIDE_CODE].includes(assetItem.flowStatus),
            storeDate: this.datePipe.transform(`${assetItem.storeDate}Z`, 'MMM yyyy'),
            storeDateFull: this.datePipe.transform(`${assetItem.storeDate}Z`, 'MMM dd, yyyy hh:mm a'),
          })) as AssetReportModel[];
          this.assetData = {
            requestAsOf: new Date(),
            assetReport: structuredResponse
          };
          this.updateReloadStatus({ assetReport: false });
          return this.assetData;
        })
      );
  }

  public setPageData(data: AssetReportPageDataModel) {
    this.assetReportPageData = data;
  }

  public getPageData(): AssetReportPageDataModel {
    return this.assetReportPageData as AssetReportPageDataModel;
  }

  public updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param)

  resetCache() {
    this.assetData = null;
    this.assetReportPageData = null;
  }
}
