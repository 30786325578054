import { inject, Injectable } from '@angular/core';
import { CompanyService } from 'src/app/admin/company/company.service';
import { CostCodeService } from 'src/app/admin/cost-code/cost-code.service';
import { DepartmentService } from 'src/app/admin/department/department.service';
import { EmployeeService } from 'src/app/admin/employee/employee.service';
import { JobService } from 'src/app/admin/job/job.service';
import { MyRequestService } from 'src/app/client/my-request/my-request.service';
import { MyTaskService } from 'src/app/client/my-task/my-task.service';
import { AssetReportService } from 'src/app/client/reports/asset-report/asset-report.service';
import { ItemReportService } from 'src/app/client/reports/item-report/item-report.service';
import { JobMaterialService } from 'src/app/client/reports/job-material/job-material.service';
import { JobRequestService } from 'src/app/client/reports/job-request/job-request.service';
import { LogDetailsService } from 'src/app/client/request-details/log-details/log-details.service';
import { AttachmentsService } from 'src/app/client/shared/attachments/attachments.service';
import { JobSummaryService } from 'src/app/client/shared/job-summary/job-summary.service';
import { DepartmentUserService } from '../department-user/department-user.service';
import { CompanyListService } from '../includes/company-list/company-list.service';
import { CostCodeListService } from '../includes/cost-code-list/cost-code-list.service';
import { PageHeaderService } from '../includes/page-header/page-header.service';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {
  private companyService = inject(CompanyService);
  private costCodeService = inject(CostCodeService);
  private departmentService = inject(DepartmentService);
  private employeeService = inject(EmployeeService);
  private jobService = inject(JobService);
  private myRequestService = inject(MyRequestService);
  private myTaskService = inject(MyTaskService);
  private assetReportService = inject(AssetReportService);
  private itemReportService = inject(ItemReportService);
  private jobMaterialService = inject(JobMaterialService);
  private jobRequestService = inject(JobRequestService);
  private logDetailsService = inject(LogDetailsService);
  private attachmentsService = inject(AttachmentsService);
  private jobSummaryService = inject(JobSummaryService);
  private departmentUserService = inject(DepartmentUserService);
  private companyListService = inject(CompanyListService);
  private costCodeListService = inject(CostCodeListService);
  private pageHeaderService = inject(PageHeaderService);
  // private requestDetailService = inject(RequestDetailService);

  resetCache() {
    this.companyService.resetCache();
    this.costCodeService.resetCache();
    this.departmentService.resetCache();
    this.employeeService.resetCache();
    this.jobService.resetCache();
    this.myRequestService.resetCache();
    this.myTaskService.resetCache();
    this.assetReportService.resetCache();
    this.itemReportService.resetCache();
    this.jobMaterialService.resetCache();
    this.jobRequestService.resetCache();
    // this.requestDetailService.resetCache();
    this.logDetailsService.resetCache();
    this.attachmentsService.resetCache();
    this.jobSummaryService.resetCache();
    this.departmentUserService.resetCache();
    this.companyListService.resetCache();
    this.costCodeListService.resetCache();
    this.pageHeaderService.resetCache();
  }
}
