import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get, isEmpty } from 'lodash';
import { Observable, map, of } from 'rxjs';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { ApiReloadStatus } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { ItemDescription, ItemReportTime } from './item-report.model';
@Injectable({
  providedIn: 'root'
})
export class ItemReportService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);

  private serviceUrl = `${API_SERVICE_URL}/createrequest`;
  private itemData: ItemReportTime | null = null;

  getDescription = (companyId: number): Observable<ItemReportTime> => {
    if (!companyId) {
      return this.pageService.errorHelper('getJobDetail: missing the required params.');
    }
    const reloadStatus: boolean = get(this.pageService.apiReloadStatus$.value, 'itemReport', false);
    const existingValue = this.itemData;
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    const getDescriptionUrl = `${this.serviceUrl}/company/${companyId}/description`;
    return this.http.get<ItemDescription[]>(getDescriptionUrl)
      .pipe(
        map((items: ItemDescription[]) => {
          this.itemData = {
            requestAsOf: new Date(),
            itemReport: items
          };
          this.updateReloadStatus({ itemReport: false });
          return this.itemData;
        }),
      );
  }

  public updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param)

  resetCache() {
    this.itemData = null;
  }
}
