import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { get, isEmpty } from 'lodash';
import { map, Observable, of } from 'rxjs';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { PageHeaderService } from '../page-header/page-header.service';
import { CostCode, CostCodeResponse, CostCodeResponseTime } from './cost-code-list.model';

@Injectable({
  providedIn: 'root'
})
export class CostCodeListService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);
  private costCodeData = signal<CostCodeResponse>({});

  private materialRequestUrl = `${API_SERVICE_URL}/materialrequisition`;

  getCostCodeList(companyId: number): Observable<CostCodeResponseTime> {
    const existingValue = get(this.costCodeData(), `[${companyId}]`, {});
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'costcode');

    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue as CostCodeResponseTime);
    }

    const costCodeUrl = `${this.materialRequestUrl}/company/${companyId}/costcode`;
    return this.http.get<CostCode[]>(costCodeUrl).pipe(
      map((response: CostCode[]) => {
        const revisedResponse: CostCodeResponseTime = {
          requestAsOf: new Date(),
          costCodes: response
        };
        this.costCodeData.update(costCodeDataSet => ({
          ...costCodeDataSet,
          [companyId]: revisedResponse,
        }));
        this.pageService.setApiReloadStatus({ costcode: false });
        return revisedResponse;
      })
    );
  }

  resetCache() {
    this.costCodeData.set({});
  }
}
