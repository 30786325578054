import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get, set } from 'lodash';
import { Observable, map, of } from 'rxjs';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { ApiReloadStatus } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { MyTaskPageDataModel, MyTaskSummaryStatus, MyTaskSummaryTime, TaskItem, TaskSummaryRequest } from './my-task.model';
@Injectable({
  providedIn: 'root'
})
export class MyTaskService {
  private http = inject(HttpClient);
  private datePipe = inject(DatePipe);
  private pageService = inject(PageHeaderService);

  private myTaskData: MyTaskSummaryStatus = {};
  private myTaskPageData: MyTaskPageDataModel = {} as MyTaskPageDataModel;

  getMyTaskSummary(param: TaskSummaryRequest): Observable<MyTaskSummaryTime> {
    const {
      requestStatus = 'progress',
      numberOfDays = 0,
    } = param;

    const existingValue = get(this.myTaskData, `[${requestStatus}][${numberOfDays}]`);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'myTaskSummary');
    if (!reloadStatus && existingValue) {
      return of(existingValue);
    }

    const myTaskSummaryUrl = `${API_SERVICE_URL}/materialrequisition/mytask?status=${requestStatus}&days=${numberOfDays}`;
    return this.http.get<TaskItem[]>(myTaskSummaryUrl)
      .pipe(
        map(taskResponse => {
          const processedTaskSummary: TaskItem[] = taskResponse
            .map((taskItem: TaskItem) => ({
              ...taskItem,
              serialNumber: taskItem.serialNumber,
              jobCode: taskItem.jobCode,
              jobName: taskItem.jobName,
              processDate: this.datePipe.transform(`${taskItem.processDate}Z`, 'MMM dd, yyyy hh:mm a') as string,
              employeeName: taskItem.employeeName,
              departmentName: taskItem.departmentName,
              companyName: taskItem.companyName,
            }));

          const dataToStore: MyTaskSummaryTime = {
            requestAsOf: new Date(),
            requests: processedTaskSummary
          }
          set(this.myTaskData, `[${requestStatus}][${numberOfDays}]`, dataToStore);
          this.setPageData({
            ...this.myTaskPageData,
            requestStatus: param.requestStatus,
            numberOfDays: param.numberOfDays,
            pageIndex: 0,
          } as MyTaskPageDataModel);
          this.updateReloadStatus({ myTaskSummary: false });
          return dataToStore;
        })
      );
  }

  public setPageData(data: MyTaskPageDataModel) {
    this.myTaskPageData = data;
  }

  public getPageData(): MyTaskPageDataModel {
    return this.myTaskPageData;
  }

  public updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param)

  resetCache() {
    this.myTaskData = {};
    this.myTaskPageData = {} as MyTaskPageDataModel;
  }
}
